exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-assets-assets-json-json-id-js": () => import("./../../../src/pages/assets/{AssetsJson.jsonId}.js" /* webpackChunkName: "component---src-pages-assets-assets-json-json-id-js" */),
  "component---src-pages-assets-js": () => import("./../../../src/pages/assets.js" /* webpackChunkName: "component---src-pages-assets-js" */),
  "component---src-pages-community-js": () => import("./../../../src/pages/community.js" /* webpackChunkName: "component---src-pages-community-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-games-games-json-json-id-js": () => import("./../../../src/pages/games/{GamesJson.jsonId}.js" /* webpackChunkName: "component---src-pages-games-games-json-json-id-js" */),
  "component---src-pages-games-js": () => import("./../../../src/pages/games.js" /* webpackChunkName: "component---src-pages-games-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-pages-tutorials-js": () => import("./../../../src/pages/tutorials.js" /* webpackChunkName: "component---src-pages-tutorials-js" */),
  "component---src-pages-tutorials-tutorials-json-json-id-js": () => import("./../../../src/pages/tutorials/{TutorialsJson.jsonId}.js" /* webpackChunkName: "component---src-pages-tutorials-tutorials-json-json-id-js" */)
}

